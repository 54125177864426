import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { MetaMaskProvider } from 'metamask-react';
import Container from './container.jsx';
import Home from './routes/home.jsx';
import Storefront from './routes/storefront.jsx';
import CountdownPage from './routes/countdown.jsx';
import CountdownPageAlternateLeft from './routes/countdown-alternate.jsx';
import CountdownPageAlternateRight from './routes/countdown-alternate-right.jsx';
import DropPage from './routes/drop.jsx';
import Login from './routes/login.jsx';
import Register from './routes/register.jsx';
import PasswordReset from './routes/reset.jsx';
import Account from './routes/account.jsx';
import EDrop from './routes/edrop.jsx';
import Setup from './routes/setup.jsx';
import Bonlook from './routes/bonlook.jsx';
import Loyalty from './routes/loyalty.jsx';
import AboutEDrop from './routes/aboutEdrop.jsx';
import AccountDetails from './components/account/details.jsx';
import AccountBilling from './components/account/billing.jsx';
import AccountPassword from './components/account/password.jsx';
import AccountWallet from './components/account/wallet.jsx';
import AccountCollectionOld from './components/account/collection.jsx';
import CollectionDetails from './components/account/collectionDetails.jsx';
import AccountCollection from './components/account/collection2.jsx';
import AssetDetails from './components/collection/assetDetails.jsx';
import MembershipDetails from './components/collection/membershipDetails.jsx';
import ContestRules from './components/contest/contestRules.jsx';
import AccountVerify from './components/account/verify.jsx';
import Auth from './components/home/auth.jsx';
import './sass/styles.scss';
import config from '../config.json';

// (async () => {
//   if (process.env.CREATOR_SUBDOMAIN) {
//     await import('./sass/components/' + process.env.CREATOR_SUBDOMAIN + '.scss');
//   }
// })();

Sentry.init({
  dsn: 'https://478b199ebcd7460ea20a47393f72fedc@o1273042.ingest.sentry.io/6467146',
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

console.log('=== JSON CONFIG ===');
console.log(config.SITE_NAME);

ReactDOM.render(
  <MetaMaskProvider>
    <BrowserRouter>
      <Routes>
        {process.env.E_DROP_ONLY === 'true'
          ? (
            <Route path="/" element={<Container />}>
              <Route index element={<Loyalty />} />
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="reset" element={<PasswordReset />}>
                <Route path=":tokenId" element={<PasswordReset />} />
              </Route>
              <Route path="account" element={<Account />}>
                <Route path="details" element={<AccountDetails />} />
                <Route path="password" element={<AccountPassword />} />
                <Route path="billing" element={<AccountBilling />} />
                <Route path="wallet" element={<AccountWallet />} />
                <Route path="verify" element={<AccountVerify />} />
                <Route path="oldCollection" element={<AccountCollectionOld />}>
                  <Route path=":nftId" element={<CollectionDetails />} />
                </Route>
              </Route>
              <Route path="account/collection" element={<AccountCollection />} />
              <Route path="asset/:nftId" element={<AssetDetails />} />
              <Route path="membership/:userAssetPointsId" element={<MembershipDetails />} />
              <Route path="contest-rules/:contestId" element={<ContestRules />} />
              <Route path="claim" element={<EDrop />} />
              <Route path="preview" element={<MembershipDetails />} />
              <Route path="setup" element={<Setup />} />
              <Route path="bonlook" element={<Bonlook />} />
              <Route path="about">
                <Route path="claim" element={<AboutEDrop />} />
              </Route>
            </Route>
          )
          : (
            <Route path="/" element={<Container />}>
              {/* <Route path="/" element={<Home />} /> */}
              <Route path="/" element={<Storefront />} />
              <Route path="drop" element={<DropPage />}>
                <Route path=":dropId" element={<DropPage />} />
              </Route>
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="reset" element={<PasswordReset />}>
                <Route path=":tokenId" element={<PasswordReset />} />
              </Route>
              <Route path="account" element={<Account />}>
                <Route path="details" element={<AccountDetails />} />
                <Route path="password" element={<AccountPassword />} />
                <Route path="billing" element={<AccountBilling />} />
                <Route path="wallet" element={<AccountWallet />} />
                <Route path="verify" element={<AccountVerify />} />
              </Route>
              <Route path="account/collection" element={<AccountCollection />}>
                <Route path=":nftId" element={<CollectionDetails />} />
              </Route>
              <Route path="asset/:nftId" element={<AssetDetails />} />
              <Route path="membership/:userAssetPointsId" element={<MembershipDetails />} />
              <Route path="claim" element={<EDrop />} />
              <Route path="setup" element={<Setup />} />
              <Route path="bonlook" element={<Bonlook />} />
              <Route path="about">
                <Route path="claim" element={<AboutEDrop />} />
              </Route>
            </Route>
          )}
        {process.env.COUNTDOWN_TEMPLATE === 'alternateLeft'
        && <Route path="/countdown" element={<CountdownPageAlternateLeft />} />}
        {process.env.COUNTDOWN_TEMPLATE === 'alternateRight'
        && <Route path="/countdown" element={<CountdownPageAlternateRight />} />}
        {!process.env.COUNTDOWN_TEMPLATE
        && <Route path="/countdown" element={<CountdownPage />} />}

      </Routes>
    </BrowserRouter>
  </MetaMaskProvider>,
  document.getElementById('app'),
);
